import React, {useState} from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import {SITE_KEY} from "../config";
import Recaptcha from "react-recaptcha";
import {DECLARATION_MESSAGE} from "../helper/constants";

const EpwForm = ({onClick}) => {

  const [isCaptchaLoaded, setIsCaptchaLoaded] = useState(false);
  const [captchaVerificationKey, setCaptchaVerificationKey] = useState("");

  const isVerified = isCaptchaLoaded && (captchaVerificationKey !== "");

  const [email, setEmail] = useState();
  const [articleId, setArticleId] = useState();
  const [agree, setAgree] = useState(false);

  const allFieldsFilled = () => {
    if (email !== "" && articleId !== "" && agree !== false) {
      return true
    } else {
      return false
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (allFieldsFilled()) {
      onClick(email, articleId)
    }

    resetRecaptcha();
    setCaptchaVerificationKey("");
  };

  // create a variable to store the component instance
  let recaptchaInstance;

// create a reset function
  const resetRecaptcha = () => {
    recaptchaInstance.reset();
  };


  return (
    <Form onSubmit={handleSubmit}>
        <Form.Group controlId="refereneNumber">
          <Form.Label>Submission Reference Number</Form.Label>
          <Form.Control type="text" placeholder="Enter reference number" onChange={(e) => setArticleId(e.target.value)}
                        value={articleId} isInvalid={articleId === ""} required/>
          <Form.Text className="text-muted">
            Enter the submission number received in your email.<sup>*</sup>
          </Form.Text>
        </Form.Group>
      <Form.Group controlId="formEmail">
          <Form.Label>Email address</Form.Label>
        <Form.Control type="email" placeholder="Enter email" onChange={(e) => setEmail(e.target.value)} value={email}
                      isInvalid={email === ""} required/>
          <Form.Text className="text-muted">
            Enter your email id used for submission.
          </Form.Text>
        </Form.Group>
      <Form.Group>
        <Form.Check label={DECLARATION_MESSAGE} onChange={(e) => setAgree(e.target.checked)} checked={agree} required/>
      </Form.Group>
        {!isVerified &&
        <Form.Text className="text-muted mt-3">
          Please Verify below to stop spam. Thank you.
        </Form.Text>
        }
        <div className="mt-2">
          <Recaptcha
            ref={e => recaptchaInstance = e}
            sitekey={SITE_KEY}
            render="explicit"
            onloadCallback={() => setIsCaptchaLoaded(true)}
            verifyCallback={setCaptchaVerificationKey}
          />
        </div>
      <Button variant="primary"
              type="submit"
              disabled={!isVerified || !allFieldsFilled()}
              style={{border: 0, borderRadius: 0, backgroundColor: '#E21A2C', marginTop: 16}}>
        Get Status
      </Button>
      </Form>
  );
};

export default EpwForm;


