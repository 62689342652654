const STATUS_MESSAGE = {
  QUEUED_FOR_REVIEW: 'Queued for Review : Submission has been received and is in queue for the review process',
  IN_REVIEW: 'In Review : Submission is under review',
  IN_REVIEW_DECISION_CONVEYED: 'In Review : Decision conveyed. Please check your email.',
  DECISION_AVAILABLE: 'Decision available : Decision conveyed. Please check your email.',
  IN_EDITING: 'In Editing : Submission is in the queue for the editing process.',
  PUBLICATION_QUEUE: 'In Publication Queue : Preliminary editing process is complete. Submission is in the queue for publication.\n' +
    'Please note our publication queue is long and the submission may take a while to be published. \n',
  PUBLISHED: 'Published : Submission has been published. Please check your email.',
  GETTING_DATA: 'Getting Data...',
};


export const getStatusMessage = (status) => STATUS_MESSAGE[status];
