import Image from "react-bootstrap/Image";
import React from "react";
import {HOME_URL} from "../helper/constants";

const epwLogo = require('../assets/epwLogo.png');

export function Header() {
  return <div className="header-wrapper">
    <a href={HOME_URL}>
      <Image src={epwLogo} className="App-logo logo-sizing" alt="logo" fluid/>
    </a>
    <div style={{marginLeft: "auto", display: "flex", alignItems: "flex-end"}}>
      <a href={HOME_URL} className="home-link-text" >
        HOME
      </a>
    </div>
  </div>;
}
