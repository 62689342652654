
export const BOTTOM_MESSAGE_2 = "Every effort is taken to complete early processing of the articles we receive. Given the high volume of articles received daily, it can take up to six months for a final decision on whether an article is accepted for publication. Articles accepted for publication can take up to 12 months from the date of acceptance to appear in print.";

export const DECLARATION_MESSAGE = "By submitting this form I declare that information provided by me is to check the status of my own submission.";


export const SITE_MESSAGE = "We deal with a large volume of mails on a daily basis, and may be unable to immediately respond or address your queries via email.";


export const API_PATH = "https://ojs.epw.in/api/getstatus.php";
export const HOME_URL = "https://www.epw.in/";
