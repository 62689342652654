import {BOTTOM_MESSAGE_2} from "../helper/constants";
import React from "react";

export function Footer() {
  return <div className="bottomBar">
    <sup>*</sup>
    Receipt of articles is immediately acknowledged by email. If contributors have not received an acknowledgement and
    reference number within a week of submission, they are requested to check their spam folders for the mail and write
    to us at <a href="mailto:edit@epw.in" target='_blank'>edit@epw.in</a>. Please refer to our <a
    href='https://www.epw.in/notes-contributors.html'
    target='_blank'> Notes for Contributors</a> for more details.

    <p>{BOTTOM_MESSAGE_2}</p>
  </div>;
}
