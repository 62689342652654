import React, {useState} from 'react';
import './css/App.css';
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import EpwForm from "./components/EpwForm";
import {API_PATH, SITE_MESSAGE} from "./helper/constants";
import {Footer} from "./components/Footer";
import {Body} from "./components/Body";
import {Header} from "./components/Header";
import axios from 'axios';
import {getStatusMessage} from "./helper/helper";


function App() {

  // To Do : check if all the fields are filled.

  const [status, setStatus] = useState({
    has_error: false,
  });

  function fetchStatus(emailId, articleId) {
    setStatus({status: 'GETTING_DATA'});
    let options = {
      method: 'GET',
      mode: 'no-cors',
      url: API_PATH,
      headers: {'Content-Type': 'application/x-www-form-urlencoded'},
      params: {
        email_id : emailId,
        article_id: articleId
      }
    };
    axios(options)
      .then(response => setStatus({...status, ...response.data}), err => setStatus({has_error: true}));
  }

  return (
    <Container className="p-3 main-body">
      <Row>
        <Col>
          <Header/>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="text-center font-weight-bold h4 site-heading">Track your submission here</div>
          <div className="responsive-config-display">{SITE_MESSAGE}</div>
          <div className="responsive-config-display">Before writing in to us for any further queries, we would
            request you to please use this tracking tool to check for updates on your submission and kindly refer to
            our <a href='https://www.epw.in/notes-contributors.html'>Notes for Contributors</a> for
            information on our processing timelines.
          </div>
        </Col>
      </Row>
      <Row className="justify-content-md-center mt-3 StatusContainerHeight ">
        <Col lg={4} md={6}>
          <EpwForm onClick={fetchStatus}/>
        </Col>
        <Col lg={8} md={6}>
          <Body statusMessage={getStatusMessage(status.status)} hasError={status.has_error} errorMessage={status.status} />
        </Col>
      </Row>
      <Row>
        <Footer/>
      </Row>
    </Container>
  );
}

export default App;
