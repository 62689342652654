import {SITE_MESSAGE, SITE_MESSAGE_2} from "../helper/constants";
import React from "react";

export function Body(props) {
  return <div style={{display: "flex", flexDirection: "column", height: "100%"}}>
    <div className="responsive-config">{SITE_MESSAGE}</div>
    <div className="responsive-config">Before writing in to us for any further queries, we would
      request you to please use this tracking tool to check for updates on your submission and kindly refer to
      our <a href='https://www.epw.in/notes-contributors.html' target='_blank' style={{color:'#e21a2c'}}>Notes for Contributors</a> for information on our processing
      timelines.</div>
    <div className="submission-title h5">Your submission status</div>
    {!props.hasError &&
    <div className="result-wrapper">
      <div className="message">{props.statusMessage}</div>
    </div>
    }
    {props.hasError &&
    <div style={{marginTop: 50}} className="result-wrapper">
      Your submission status : <span className="message error">Error processing request. {props.errorMessage} </span>
    </div>
    }

  </div>;
};

